import { format, subMonths } from 'date-fns'
import { useLazyGetTasksAndGroupTasksByContactsQuery } from 'features/Tasks/api/dynamics'
import { isNotNullOrUndefined } from 'shared/guards'
import { useCopilotMemory } from 'store/api/rcopilot'
import {
  useLazyGetRevenueHistoryByAccountQuery,
  useLazyRdot360_getPerformanceQuery
} from '../store/datahub'
import { useLazyGetInteractionsByContactsQuery } from '../store/dynamics'
import {
  useLazyGetIncomeTaxSummaryQuery,
  useLazyGetPositionByCategoryQuery
} from '../store/holdingsApi'
import {
  useLazyGetDetailActivitySummaryQuery,
  useLazyGetRealizedGainLossSecurityQuery
} from '../store/rdot360AnalyticsApi'
import {
  useRdot360AccountContext,
  useRdot360ClientContext,
  useRdot360Context,
  useRdot360SelectedAccountsApiContext
} from '../store/rdot360Context'

export const useRdot360DataFetch = () => {
  const { selectedHouseholdId } = useRdot360Context()
  const { householdAccounts } = useRdot360AccountContext()
  const { addObjectToMemory } = useCopilotMemory()
  const { apiContextAccounts, apiKey } = useRdot360SelectedAccountsApiContext()
  const [getActivity] = useLazyGetDetailActivitySummaryQuery()
  const [getInvestments] = useLazyGetPositionByCategoryQuery()
  const [getTasks] = useLazyGetTasksAndGroupTasksByContactsQuery()
  const [getPerformance] = useLazyRdot360_getPerformanceQuery()
  const [getRevenue] = useLazyGetRevenueHistoryByAccountQuery()
  const [getIncome] = useLazyGetIncomeTaxSummaryQuery()
  const [getGainLoss] = useLazyGetRealizedGainLossSecurityQuery()
  const [getInteractions] = useLazyGetInteractionsByContactsQuery()
  const { profiles } = useRdot360ClientContext()
  const getRdot360Data = async () => {
    const [
      activityData,
      investmentsData,
      tasksData,
      performanceData,
      revenueData,
      incomeData,
      gainLossData,
      interactionsData
    ] = await Promise.all([
      getActivity({
        contextAccounts: apiContextAccounts,
        dateRange: `FromTo,${format(
          subMonths(new Date(), 1),
          'yyyy-MM-dd'
        )},${format(new Date(), 'yyyy-MM-dd')}`
      }),
      getInvestments({ contextId: apiKey, category: 'L4' }),
      getTasks({
        contactIds:
          profiles?.map((x) => x.id)?.filter(isNotNullOrUndefined) || []
      }),
      getPerformance(selectedHouseholdId || ''),
      getRevenue({
        accounts:
          householdAccounts
            ?.map((x) => x.accountId)
            ?.filter(isNotNullOrUndefined) || [],
        startDate: format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        categoryType: 'detail'
      }),
      getIncome({
        accounts:
          householdAccounts
            ?.map((x) => x.accountId)
            ?.filter(isNotNullOrUndefined) || [],
        contextId: apiKey,
        type: 'historical',
        financialYear: new Date().getFullYear()
      }),
      getGainLoss({
        accounts:
          householdAccounts
            ?.map((x) => x.accountId)
            ?.filter(isNotNullOrUndefined) || [],
        financialYear: new Date().getFullYear(),
        taxable: false
      }),
      getInteractions({
        contactIds:
          profiles?.map((x) => x.id)?.filter(isNotNullOrUndefined) || []
      })
    ])
    await Promise.all([
      addObjectToMemory(activityData, 'copilot-client-dashboard', ['activity']),
      addObjectToMemory(investmentsData, 'copilot-client-dashboard', [
        'holdings'
      ]),
      addObjectToMemory(tasksData, 'copilot-client-dashboard', ['tasks']),
      addObjectToMemory(performanceData, 'copilot-client-dashboard', [
        'performance'
      ]),
      addObjectToMemory(revenueData, 'copilot-client-dashboard', ['revenue']),
      addObjectToMemory(incomeData, 'copilot-client-dashboard', ['income']),
      addObjectToMemory(gainLossData, 'copilot-client-dashboard', ['gainLoss']),
      addObjectToMemory(interactionsData, 'copilot-client-dashboard', [
        'interactions'
      ])
    ])
  }
  return { getRdot360Data }
}
