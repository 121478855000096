import { css } from '@emotion/react'
import { useNotes_addNoteMutation } from 'features/Notes/api/dynamics'
import { useRdot360ClientContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context/useRdot360ClientContext'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Chat from './components/chat/Chat'
import { useAuth } from './libs/hooks/useAuth'
import { IChatMessage, AuthorRoles } from './libs/models/ChatMessage'
import { INotesAction } from './libs/models/NotesAction'
import {
  addMessageToConversationFromServer,
  updateBotResponseStatus,
  updateMessageProperty,
  updateFinalMessageFlag,
  updateFinalActionFlag
} from './redux/features/conversations/conversationsSlice'
import Connector from './redux/features/message-relay/signalRConnection'

export const classes = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    width: '100%',
    overflow: 'hidden'
  }),
  header: css({
    alignItems: 'center',
    backgroundColor: '#f0f0f0', //colorNeutralBackground4
    color: '#ffffff', //colorNeutralForegroundOnBrand
    display: 'flex',
    '& h1': {
      paddingLeft: '20px',
      display: 'flex'
    },
    height: '48px',
    justifyContent: 'space-between',
    width: '100%'
  }),
  persona: css({
    marginRight: '24px'
  }),
  cornerItems: css({
    display: 'flex',
    gap: '8px'
  }),
  button: css({
    backgroundColor: '#f4f4f4'
  })
}

export enum AppState {
  ProbeForBackend,
  SettingUserInfo,
  ErrorLoadingChats,
  ErrorLoadingUserInfo,
  LoadingChats,
  Chat,
  SigningOut
}

interface CopilotProps {
  showCopilot: boolean
  setShowCopilot: React.Dispatch<React.SetStateAction<boolean>>
}

const Copilot: React.FC<CopilotProps> = ({ showCopilot, setShowCopilot }) => {
  const [addNote] = useNotes_addNoteMutation()
  const { profileIds } = useRdot360ClientContext()
  const { getToken, apiBaseUrl } = useAuth()
  const dispatch = useDispatch()
  const isInitialMount = useRef(true)

  const fetchData = useCallback(async () => {
    // Connect to signal R
    const token = await getToken()
    const connector = Connector(`${apiBaseUrl}/rcopilot`, token ?? '')

    const registerHandlers = () => {
      if (!isInitialMount.current) {
        return
      }
      isInitialMount.current = false
      //Update existing bot response for chat streaming and indicates the last update to the bot response from the server
      connector.receiveChatMessageUpdate(
        (message: IChatMessage, finalMessageFlag: boolean) => {
          const { chatId, id, content } = message
          dispatch(
            updateMessageProperty({
              chatId,
              messageIdOrIndex: id ?? '',
              property: 'content',
              value: content,
              frontLoad: false
            })
          )
          if (finalMessageFlag) {
            dispatch(
              updateFinalMessageFlag({
                chatId: chatId,
                flag: true
              })
            )
          }
        }
      )

      //Receives initial bot response
      connector.receiveInitialChatMessage(
        (chatId: string, senderId: string, message: IChatMessage) => {
          message.authorRole = AuthorRoles.Bot
          message.userName = 'RCopilot'
          dispatch(addMessageToConversationFromServer({ message, chatId }))
        }
      )

      //Updates the response loading message
      connector.loadingChatMessage(
        (chatId: string, status: string | undefined) => {
          dispatch(updateBotResponseStatus({ chatId, status }))
        }
      )

      //Re-enables the chat
      connector.receiveFinalActionFlag((chatId: string) => {
        dispatch(
          updateFinalActionFlag({
            chatId: chatId,
            flag: true
          })
        )
        dispatch(
          updateBotResponseStatus({
            chatId: chatId,
            status: undefined
          })
        )
      })

      //Saves the note, updates the bot response, then re-enables the chat
      connector.receiveNoteActionFlag(
        (chatId: string, noteJson: INotesAction, chatMessage: IChatMessage) => {
          const updateChatMessage = (
            chatId: string,
            messageId: string,
            content: string
          ) => {
            dispatch(
              updateMessageProperty({
                chatId,
                messageIdOrIndex: messageId,
                property: 'content',
                value: content,
                frontLoad: false
              })
            )
            dispatch(updateBotResponseStatus({ chatId, status: undefined }))
            dispatch(updateFinalActionFlag({ chatId, flag: true }))
          }
          if (
            (noteJson.type !== 'contact' && noteJson.type !== 'org') ||
            !noteJson.note_text ||
            !noteJson.title
          ) {
            const content = `${chatMessage.content} \n\nYour note could not be saved.`
            updateChatMessage(chatId, chatMessage.id ?? '', content)
          } else {
            addNote({
              notePayload: {
                subject: noteJson.title,
                notetext: noteJson.note_text,
                'objectid_contact@odata.bind':
                  noteJson.type === 'contact'
                    ? `/contacts(${noteJson.contact_id})`
                    : undefined,
                'objectid_account@odata.bind':
                  noteJson.type === 'org'
                    ? `/accounts(${noteJson.contact_id})`
                    : undefined
              },
              contacts: profileIds
            })
              .unwrap()
              .then(() => {
                const content = `${chatMessage.content} \n\nYour note has been saved successfully!`
                updateChatMessage(chatId, chatMessage.id ?? '', content)
              })
              .catch((error) => {
                const content = `${chatMessage.content} \n\nYour note could not be saved.`
                console.error('Error saving the note:', error)
                updateChatMessage(chatId, chatMessage.id ?? '', content)
              })
          }
        }
      )
    }
    registerHandlers()
  }, [getToken, apiBaseUrl, dispatch, addNote, profileIds])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <Chat showCopilot={showCopilot} setShowCopilot={setShowCopilot} />
}

export default Copilot
export { copilotModuleReducer } from './redux'
