import { css } from '@emotion/react'
import { DefaultButton } from '@fluentui/react'
import { GetResponseOptions } from '../../libs/hooks/useChat'
import { ChatMessageType } from '../../libs/models/ChatMessage'
import { ChatState } from '../../redux/features/conversations/ChatState'

const classes = {
  prompts: css({
    margin: '0 auto',
    width: '75%',
    color: '#323130',
    fontSize: '14px',
    fontWeight: '400'
  }),
  buttonGrid: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
    gridAutoRows: '1fr',
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr'
    },
    marginBottom: '38%'
  }),
  header: css({
    fontWeight: '400',
    color: '#808080',
    textAlign: 'center'
  }),
  input: css({
    display: 'flex',
    margin: '0px 12px',
    marginTop: 'auto'
  }),
  inputButtonGrid: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '8px',
    marginBottom: '10px',
    gridAutoRows: 'minmax(auto, max-content)',
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr'
    }
  })
}
interface ChatPromptsProps {
  onSubmit: (options: GetResponseOptions) => Promise<void>
  selectedId: string
  conversation: ChatState
}
export const ChatPrompts: React.FC<ChatPromptsProps> = ({
  onSubmit,
  selectedId,
  conversation
}) => {
  const defaultPrompts =
    conversation.suggestedQuestions != null
      ? conversation.suggestedQuestions
      : []

  const messagesLength = conversation.messages.length
  const renderPrompts = () => {
    return defaultPrompts.map((prompt, index) => (
      <DefaultButton
        disabled={
          !conversation.finalActionFlag || !conversation.finalMessageFlag
        }
        styles={{
          root: {
            border: 'none',
            borderRadius: '4px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            height: '100%',
            padding: '7px',
            margin: '5px'
          },
          label: {
            color: '#808080',
            fontWeight: '400',
            fontSize: '14px'
          }
        }}
        key={index}
        onClick={() =>
          onSubmit({
            value: prompt,
            messageType: ChatMessageType.Message,
            advancedReasoning: false,
            chatId: selectedId
          })
        }
      >
        {prompt}
      </DefaultButton>
    ))
  }

  return (
    <div
      // ref={containerRef}
      css={messagesLength <= 1 ? classes.prompts : classes.input}
    >
      {messagesLength <= 1 && defaultPrompts.length !== 0 && (
        <div>
          <h2 css={classes.header}>Get started with some questions!</h2>
          <div css={classes.buttonGrid}>{renderPrompts()}</div>
        </div>
      )}
      {messagesLength > 1 && defaultPrompts.length !== 0 && (
        <div css={classes.inputButtonGrid}>{renderPrompts()}</div>
      )}
    </div>
  )
}
