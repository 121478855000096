import * as signalR from '@microsoft/signalr'
import { IChatMessage } from '../../../libs/models/ChatMessage'
import { INotesAction } from '../../../libs/models/NotesAction'

const enum SignalRCallbackMethods {
  ReceiveMessage = 'ReceiveMessage',
  ReceiveMessageUpdate = 'ReceiveMessageUpdate',
  ReceiveUserTypingState = 'ReceiveUserTypingState',
  ReceiveLoadingMessage = 'ReceiveLoadingMessage',
  ReceiveFinalActionFlag = 'ReceiveFinalActionFlag',
  ReceiveNoteActionFlag = 'ReceiveNoteActionFlag'
}

class Connector {
  private connection: signalR.HubConnection
  static instance: Connector
  private handlersRegistered: Set<string> = new Set()

  constructor(apiBaseUrl: string, token: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(apiBaseUrl, {
        accessTokenFactory: () => {
          return token
        }
      })
      .withUrl(`${apiBaseUrl}/messageRelayHub`)
      .withAutomaticReconnect()
      .withHubProtocol(new signalR.JsonHubProtocol())
      .configureLogging(signalR.LogLevel.Information)
      .build()

    this.connection
      .start()
      .then(() => console.log('SignalR copilot connection started.'))
      .catch((err: string) => console.error(err))
  }

  public static getInstance(apiBaseUrl: string, token: string): Connector {
    if (!Connector.instance) {
      Connector.instance = new Connector(apiBaseUrl, token)
    }
    return Connector.instance
  }

  //Ensures each signal R handler is setup once
  public registerHandlers(
    handlerName: string,
    callback: (...args: any[]) => void
  ) {
    if (this.handlersRegistered.has(handlerName)) {
      return
    }
    this.connection.on(handlerName, callback)
    this.handlersRegistered.add(handlerName)
  }

  //Update existing bot response for chat streaming and indicates the last update to the bot response from the server
  public receiveChatMessageUpdate(
    callback: (message: IChatMessage, finalMessageFlag: boolean) => void
  ) {
    this.registerHandlers(SignalRCallbackMethods.ReceiveMessageUpdate, callback)
  }

  //Receives initial bot response
  public receiveInitialChatMessage(
    callback: (chatId: string, senderId: string, message: IChatMessage) => void
  ) {
    this.registerHandlers(SignalRCallbackMethods.ReceiveMessage, callback)
  }

  //Updates the response loading message
  public loadingChatMessage(
    callback: (chatId: string, status: string | undefined) => void
  ) {
    this.registerHandlers(
      SignalRCallbackMethods.ReceiveLoadingMessage,
      callback
    )
  }

  //Re-enables the chat
  public receiveFinalActionFlag(callback: (chatId: string) => void) {
    this.registerHandlers(
      SignalRCallbackMethods.ReceiveFinalActionFlag,
      callback
    )
  }

  //Saves the note then re-enables the chat
  public receiveNoteActionFlag(
    callback: (
      chatId: string,
      noteJson: INotesAction,
      chatMessage: IChatMessage
    ) => void
  ) {
    this.registerHandlers(
      SignalRCallbackMethods.ReceiveNoteActionFlag,
      callback
    )
  }

  // Add with each addMessageToConversationFromUser call from conversationSlice
  public async sendMessageAsync(
    chatId: string,
    activeUserInfo: string,
    message: IChatMessage
  ) {
    while (
      Connector.instance.connection.state !==
      signalR.HubConnectionState.Connected
    ) {
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    Connector.instance.connection
      .invoke('SendMessageAsync', chatId, activeUserInfo, message)
      .catch((err) => console.log('sendMessageAsync error: ', err))
  }

  // Add with each updateUserIsTyping call from conversationSlice
  public async sendUserTypingStateAsync(
    chatId: string,
    userId: string,
    isTyping: boolean
  ) {
    while (
      Connector.instance.connection.state !==
      signalR.HubConnectionState.Connected
    ) {
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    Connector.instance.connection
      .invoke('SendUserTypingStateAsync', chatId, userId, isTyping)
      .catch((err) => console.log('sendUserTypingStateAsync error: ', err))
  }

  // Add with each setConversations AND addConveration call from conversationSlice
  public async addClientToGroupAsync(id: string) {
    while (
      Connector.instance.connection.state !==
      signalR.HubConnectionState.Connected
    ) {
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    Connector.instance.connection
      .invoke('addClientToGroupAsync', id)
      .catch((err) => console.log('addClientToGroupAsync error: ', err))
  }
}

export default Connector.getInstance
