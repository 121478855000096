import { css } from '@emotion/react'
import { IconButton } from '@fluentui/react/lib/Button'
import {
  ChoiceGroup,
  IChoiceGroupOption
} from '@fluentui/react/lib/ChoiceGroup'
import { IIconProps } from '@fluentui/react/lib/Icon'
import { TooltipHost } from '@fluentui/react/lib/Tooltip'
import React, { useCallback, useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useChat, GetResponseOptions } from '../../libs/hooks/useChat'
import { ChatMessageType } from '../../libs/models/ChatMessage'
import { useApp } from '../../redux/features/app/appSlice'
import {
  editConversationInput,
  updateUserIsTyping,
  useConversations,
  updateAdvancedReasoning
} from '../../redux/features/conversations/conversationsSlice'
import { Alerts } from '../shared/Alerts'
import { ChatStatus } from './ChatStatus'

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '105em',
    margin: '0px 12px'
  }),
  typingIndicator: css({
    maxHeight: '28px'
  }),
  content: css({
    gap: '12px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }),
  input: css({
    width: '100%',
    padding: '2px'
  }),
  textarea: css({
    maxHeight: '80px'
  }),
  controls: css({
    display: 'flex',
    flexDirection: 'row'
  }),
  essentials: css({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto' // align to right
  }),
  functional: css({
    display: 'flex',
    flexDirection: 'row'
  }),
  dragAndDrop: css({
    border: '2px solid #030302',
    padding: '8px',
    textAlign: 'center',
    backgroundColor: '#e8ebf9',
    fontSize: '10px',
    color: '#e8ebf9',
    caretColor: 'transparent'
  })
}
const sendIcon: IIconProps = { iconName: 'Send' }

const reasoningOptions: IChoiceGroupOption[] = [
  {
    key: 'A',
    text: 'Simple Reasoning',
    ariaLabel: 'SimpleReasoning',
    onRenderField: (props, render) => {
      return (
        <TooltipHost
          content={
            'For quick, straightforward answers. Ideal for basic queries and simple tasks.'
          }
          // In this mode, aria-describedby is automatically added/removed based on tooltip visibility
        >
          {render!(props)}
        </TooltipHost>
      )
    }
  },
  {
    key: 'B',
    text: 'Advanced Reasoning',
    ariaLabel: 'Advanced Reasoning',
    onRenderField: (props, render) => {
      return (
        <TooltipHost
          content={
            'For more complex, detailed responses. Perfect for calculations and in-depth analysis.'
          }
        >
          {render!(props)}
        </TooltipHost>
      )
    }
  }
]

interface ChatInputProps {
  onSubmit: (options: GetResponseOptions) => Promise<void>
}

export const ChatInput: React.FC<ChatInputProps> = ({ onSubmit }) => {
  const chat = useChat()
  const dispatch = useDispatch()
  const { conversations, selectedId } = useConversations()
  const { activeUserInfo } = useApp()
  const [value, setValue] = useState('')
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    // Focus on the text area when the selected conversation changes
    textAreaRef.current?.focus()
  }, [selectedId])

  const handleSubmit = (
    value: string,
    messageType: ChatMessageType = ChatMessageType.Message
  ) => {
    if (value.trim() === '') {
      return // only submit if value is not empty
    }
    setValue('')
    onSubmit({
      value: value,
      messageType: messageType,
      advancedReasoning: conversations[selectedId].advancedReasoning,
      chatId: selectedId
    }).catch((error) => {
      const message = `Error submitting chat input: ${(error as Error).message}`
      console.log(message)
    })
  }

  const onChange = useCallback(
    (ev?: React.SyntheticEvent<HTMLElement>, option?: IChoiceGroupOption) => {
      dispatch(
        updateAdvancedReasoning({
          chatId: selectedId,
          advancedReasoning: option?.key === 'B'
        })
      )
    },
    [dispatch, selectedId]
  )

  const choiceGroupStyles = {
    label: {
      display: 'inline'
    },
    flexContainer: {
      columnGap: '1em',
      display: 'inline-flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  }

  return (
    <div css={classes.root}>
      <div css={classes.typingIndicator}>
        <ChatStatus />
      </div>
      <Alerts />
      <div css={classes.content}>
        <textarea
          placeholder="Ask a question"
          title="Chat input"
          aria-label="Chat input field. Click enter to submit input."
          ref={textAreaRef}
          id="chat-input"
          css={[classes.input, classes.textarea]}
          style={{ padding: '10px', outline: 'none', borderRadius: '5px' }}
          value={value}
          onFocus={() => {
            // update the locally stored value to the current value
            const chatInput = document.getElementById('chat-input')
            if (chatInput) {
              setValue((chatInput as HTMLTextAreaElement).value)
            }
            // User is considered typing if the input is in focus
            if (activeUserInfo && selectedId !== '') {
              dispatch(
                updateUserIsTyping({
                  userId: activeUserInfo.id,
                  chatId: selectedId,
                  isTyping: true
                })
              )
            }
          }}
          onChange={(event) => {
            setValue(event.target.value)
            dispatch(
              editConversationInput({
                id: selectedId,
                newInput: event.target.value
              })
            )
          }}
          onKeyDown={(event: any) => {
            if (
              event.key === 'Enter' &&
              !event.shiftKey &&
              conversations[selectedId].finalActionFlag &&
              conversations[selectedId].finalMessageFlag
            ) {
              event.preventDefault()
              handleSubmit(value)
            }
          }}
          onBlur={async () => {
            // User is considered not typing if the input is not  in focus
            if (activeUserInfo) {
              chat.updateUserTyping(activeUserInfo.id, selectedId, false)
            }
          }}
        />
      </div>
      <div css={classes.controls}>
        <ChoiceGroup
          styles={choiceGroupStyles}
          selectedKey={
            conversations[selectedId].advancedReasoning ? 'B' : 'A' ?? 'A'
          }
          options={reasoningOptions}
          onChange={onChange}
        />
        <div css={classes.essentials}>
          <IconButton
            title="Submit"
            aria-label="Submit message"
            iconProps={sendIcon}
            onClick={() => {
              handleSubmit(value)
            }}
            disabled={
              !conversations[selectedId].finalActionFlag ||
              !conversations[selectedId].finalMessageFlag
            }
          />
        </div>
      </div>
    </div>
  )
}
