import { css, Theme, useTheme } from '@emotion/react'
import React, { useEffect, useMemo } from 'react'
import { useCopilotMemory } from 'store/api/rcopilot'
import { TodaysChange } from '../../../components/Balances/TodaysChange'
import { useBalanceSummary } from '../../../components/Balances/useBlalanceSummary'
import { amountFormatter } from '../../../components/shared/Formatters'
import { IndeterminateProgressIndicator } from '../../../components/shared/ProgressIndicator/IndeterminateProgressIndicator'
import { SnackBar } from '../../../components/shared/Snackbar'
import { USDCellWithColor } from '../../../components/USDCellWithColor'

export const getBalanceSummaryClasses = (theme: Theme) => ({
  dottedLine: css({
    border: 'none',
    borderTop: `1px dotted ${theme.colors.extraGray1}`,
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryWhite,
    height: '1px',
    margin: '6px 0'
  }),
  dataLabel: css({
    width: '45%',
    fontSize: theme.size.sm
  }),
  amount: css({
    width: '30%',
    fontSize: theme.size.sm,
    textAlign: 'right'
  }),
  change: css({
    width: '27%',
    textAlign: 'right',
    fontSize: theme.size.sm
  }),
  headerRow: css({
    display: 'flex',
    padding: '0 6px',
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: 6
  }),
  boldText: css({
    fontWeight: 'bold'
  }),
  totalRow: css({
    display: 'flex',
    margin: '8px 0',
    backgroundColor: theme.colors.secondaryWhite2,
    padding: '8px 6px'
  }),
  subRowContainer: css({
    display: 'flex',
    padding: '0 6px ',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }),
  link: css({
    '& *:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }),
  loader: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  })
})

export const BalanceSummaryTable = () => {
  const theme = useTheme()
  const classes = useMemo(() => getBalanceSummaryClasses(theme), [theme])
  const {
    totalBalanceSummary,
    balanceSummaryData,
    isBalanceSummaryError,
    balanceSummaryRefetch,
    isBalanceSummaryLoading,
    isUninitialized
  } = useBalanceSummary()
  const { addObjectToMemory } = useCopilotMemory()
  useEffect(() => {
    if (isBalanceSummaryLoading || isUninitialized) {
      return
    }
    addObjectToMemory(balanceSummaryData, 'copilot-client-dashboard', [
      'balances'
    ])
  }, [
    addObjectToMemory,
    balanceSummaryData,
    isBalanceSummaryLoading,
    isUninitialized
  ])

  const balanceSummaryError = useMemo(
    () => isBalanceSummaryError,
    [isBalanceSummaryError]
  )
  const tableErrorMessage =
    (isBalanceSummaryError as Error)?.message || 'An unknown Error Occurred'

  return (
    <div>
      <>
        {balanceSummaryError && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar
              message={tableErrorMessage}
              type="Failure"
              actionButtonLabel="click to retry"
              onActionButtonClicked={balanceSummaryRefetch}
            />
          </div>
        )}
      </>

      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <div css={classes.headerRow}>
            <div css={[classes.dataLabel]}>&nbsp;</div>
            <div css={[classes.amount, classes.boldText]}>Amount</div>
            <div css={[classes.change, classes.boldText]}>{"Day's change"}</div>
          </div>
          <hr css={classes.dottedLine} />
        </div>

        {balanceSummaryData.map((item, index) => (
          <React.Fragment key={item.label}>
            {index > 0 && (
              <div>
                {item.label === 'Available to Withdraw' ? (
                  <div css={classes.totalRow}>
                    <div css={[classes.dataLabel, classes.boldText]}>Total</div>
                    <div css={classes.amount}>
                      {amountFormatter(totalBalanceSummary.amount)}
                    </div>
                    <div
                      css={[
                        classes.change,
                        { display: 'flex', justifyContent: 'flex-end' }
                      ]}
                    >
                      <TodaysChange
                        change={totalBalanceSummary.change}
                        changeDetails={totalBalanceSummary.todayschange?.RCM}
                        onChangeClick={totalBalanceSummary?.onClick}
                        totalNonNfsValueChange={
                          totalBalanceSummary?.totalNonNfsValueChange
                        }
                        totalaccountvaluechange={
                          totalBalanceSummary?.totalaccountvaluechange
                        }
                        totalotherassetsvaluechange={
                          totalBalanceSummary?.totalotherassetsvaluechange
                        }
                        totalexternalaccountvaluechange={
                          totalBalanceSummary?.totalexternalaccountvaluechange
                        }
                        displayOtherChanges={true}
                      />
                    </div>
                  </div>
                ) : (
                  <hr css={classes.dottedLine} />
                )}
              </div>
            )}
            <div css={classes.subRowContainer}>
              <div css={classes.dataLabel}>{item.label}</div>
              <div css={[classes.amount]}>
                {item.override || amountFormatter(item.amount)}
              </div>
              <div
                css={[classes.change, item?.onClick && classes.link]}
                onClick={() => item.change && item?.onClick && item.onClick()}
              >
                {item.change ? <USDCellWithColor value={item.change} /> : '--'}
              </div>
            </div>
          </React.Fragment>
        ))}
        <div
          css={{
            fontSize: '10px',
            fontWeight: 300,
            marginTop: '6px',
            bottom: '8px',
            position: 'absolute'
          }}
        >
          Cash excludes Money Market Funds, which are currently included in
          Priced Investments. Available to Withdraw and Invest data includes
          Rockefeller accounts at NFS and Other Custodians (Cash and Sweep
          Funds) but excludes External Accounts.
        </div>
      </div>
      {isBalanceSummaryLoading && (
        <div css={classes.loader}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </div>
  )
}
